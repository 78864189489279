import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { hyphenateSync as hyphenate } from "hyphen/de";


class ParagraphHeroText extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-hero-text": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-16">
              <div
                className="hero text"
                dangerouslySetInnerHTML={{
                  __html: hyphenate(this.props.content.fieldText.processed, { minWordLength: 10 }),
                }}
              />
            </div>
          </div>
        </div>

        {this.props.content.fieldHint && (
          <div className="hint text">
            <div className="container">
              <div className="row">
                <div className="col-16">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.content.fieldHint.processed,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}
/**
 * A module representing a hero-text paragraph.
 *
 * @todo In CS we have a field "fieldHintWithColouredBackgro" but we do not use it?
 */
ParagraphHeroText.propTypes = {
  /**
   * The modules content.
   */
  content: PropTypes.shape({
    fieldText: PropTypes.shape({
      processed: PropTypes.string.isRequired,
    }),
    fieldHint: PropTypes.shape({
      processed: PropTypes.string,
    }),
  }).isRequired,
};

export default ParagraphHeroText;
