import React, { useState, useEffect } from "react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

export const Ticket = (props) => {
  if (props.content.fieldTicketlink && !props.content.fieldOmitted && !props.content.fieldFreeEntry) {
    return(
      <div className="buy-ticket">
        {props.content.fieldTicketPrice &&
          <div className="ticket-price">
            <span>
              {props.content.fieldTicketPrice}
            </span>
          </div>
        }
        <a href={props.content.fieldTicketlink.url.path} className="btn square">
          <FormattedMessage id={"event.buyTicket"}/>
        </a>
      </div>
    );
  }

  if (props.content.fieldTicketPrice  && !props.content.fieldTicketlink) {
    return(
      <div className="ticket-price no-btn">
        <span>
          Tickets: {props.content.fieldTicketPrice}
        </span>
      </div>
    );
  }

  if (props.content.fieldFreeEntry  && !props.content.fieldTicketlink) {
    return(
      <div className="ticket-price no-btn">
        <span>
          <FormattedMessage id={"event.ticket_free"}/>
        </span>
      </div>
    );
  }
};
