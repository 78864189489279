import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import menuQuery from "../general-components/menu-query.graphql";
import configQuery from "../config-query.graphql";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock-upgrade";
import LogoButton from "../general-components/logo-button";
import {encodeUrl} from "../lib/encode-url";
import ShortcutMenu from "./shortcut-menu";
import MenuButton from "./menu-button";
import LanguageSwitch from "../i18n/language-switch";
import Search from "../search/search";
import {menuCtaAction, menuOpenAction, searchOpenAction} from "../app-actions";
import {scrollOptions} from "../lib/scroll-options";
import { FullscreenMenu } from "./fullscreen-menu";
import useScreenSize from "../hooks/use-screen-size"

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
  microSite: reduxStore.appStore.microSite,
  isMobile: reduxStore.appStore.isMobile,
  menuCTAConfig: reduxStore.appStore.menuCTAConfig,
  auth: reduxStore.auth,
  menuOpen: reduxStore.appStore.menuOpen
});

/**
 * Close on menu link click.
 */
class SimpleNavigation extends Component {
  state = {
    openTree: false,
    activeLevel: 1,
    activeLink: [],
    transitionBlocked: false
  };

  clickLevelLink = (newLevel, newActiveLink) => {
    // Save which first Level Link is selected
    let activeFirstLevel = null,
      activeLink = this.state.activeLink;

    // Remove last added item if new link is on same level
    if (newLevel === this.state.activeLevel) {
      activeLink.pop();
    }

    if (newLevel === 2) {
      activeFirstLevel = newActiveLink;
      // Save top level differently and always tart from scratch when changing first level links
      this.setState({
        activeFirstLevel,
        activeLevel: newLevel,
        activeLink: [newActiveLink]
      });
    } else {
      this.setState({
        activeLevel: newLevel,
        activeLink: activeLink ? [...activeLink, newActiveLink] : [newActiveLink]
      });
    }
  }

  toggleFullScreenMenu = () => {
    const newMenuState = !this.props.menuOpen;

    this.props.dispatch(menuOpenAction(newMenuState));

    // Wait for the state to update
    setTimeout(() => {
      if (newMenuState) {
        disableBodyScroll(document.getElementById('site-header'), scrollOptions);
      } else {
        enableBodyScroll(document.getElementById('site-header'), scrollOptions);

        // Reset menu state after animation
        setTimeout(() => {
          this.setState({
            activeLevel: 1,
            activeLink: false
          });
        }, 500);
      }
    }, 0); // Ensure the dispatch has executed
  };


  shortcutToMenuLink = (linkLabel) => {
    // Temporary deactivate menu transition, because otherwise we have divergent animations
    this.setState({
      transitionBlocked: true
    });

    setTimeout(() => {
      this.setState({
        transitionBlocked: false
      });
    }, 500);

    this.clickLevelLink(2, encodeUrl(linkLabel));
    this.toggleFullScreenMenu();
  }

  handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      this.toggleFullScreenMenu();
    }
  };

  menuGoBack = () => {
    let activeLink = this.state.activeLink;

    activeLink.pop();

    this.setState({
      activeLevel: this.state.activeLevel - 1,
      activeLink: activeLink
    });
  }

  showGoBackButton = (menuLevel) => {
    let condition = this.state.activeLevel > 1 && (this.state.activeLevel) === (menuLevel + 1);

    if (!useScreenSize("lg")) {
      condition = this.state.activeLevel > 1 && (this.state.activeLevel - 1) === (menuLevel + 1);
    }

    return condition ? "show" : "hide";
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.menuCtaConfig !== prevProps.menuCtaConfig) &&
      (this.props.menuCtaConfig && this.props.menuCtaConfig.configPagesQuery?.entities[0])
    ) {
      this.props.dispatch(menuCtaAction(this.props.menuCtaConfig.configPagesQuery?.entities[0]));
    }
  }

  render() {
    return (
      <>
        <div className={"navigation standard"}>
          <div className="fixed-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-8 col-md-5 col-lg-8 panic-button-wrapper">
                  <LogoButton type={"standard"}/>
                </div>
                <div className="col-8 toggle-wrapper">
                  <>
                    <Search/>
                    <LanguageSwitch/>
                    <MenuButton
                      menuOpen={this.props.menuOpen}
                      onClick={this.toggleFullScreenMenu}
                      onKeyDown={this.handleKeyDown}
                      closeOnly={false}
                    />
                  </>
                </div>
              </div>
            </div>
          </div>
          <FullscreenMenu
            menuOpen={this.props.menuOpen}
            isMobile={this.props.isMobile}
            toggleFullScreenMenu={this.toggleFullScreenMenu}
            handleKeyDown={this.handleKeyDown}
            closeOnly={false}
            mainMenuQuery={this.props.mainMenuQuery}
            menuCtaConfig={this.props.menuCtaConfig}
            oftenClickedMenuQuery={this.props.oftenClickedMenuQuery}
            showGoBackButton={this.showGoBackButton}
            menuGoBack={this.menuGoBack}
            auth={this.props.auth}
            clickLevelLink={this.clickLevelLink}
            activeLevel={this.state.activeLevel}
            activeLink={this.state.activeLink}
            activeFirstLevel={this.state.activeFirstLevel}
            microSite={this.props.microSite}
            transitionBlocked={this.state.transitionBlocked}
          />
        </div>
        <div className={"navigation extended"}>
          <div className="fixed-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-13 col-md-5 panic-button-wrapper">
                  <LogoButton type={"extended"}/>
                </div>
                {!this.props.mainMenuQuery.loading &&
                  this.props.mainMenuQuery.menuByName &&
                  <ShortcutMenu
                    links={this.props.mainMenuQuery.menuByName.links}
                    config={this.props.menuCtaConfig.configPagesQuery?.entities[0]}
                    shortcutToMenuLink={this.shortcutToMenuLink}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

SimpleNavigation.propTypes = {
  mainMenuQuery: PropTypes.object,
  oftenClickedMenuQuery: PropTypes.object,
};

const getMenuConfig = () => {

}

export default connect(mapStateToProps)(
  compose(
    graphql(menuQuery, {
      options: (props) => ({
        variables: {
          name: props.microSite === "gdf" ? 'menue-gdf' : props.microSite === "kv" ? 'menue-kv' : props.microSite === "wt" ? 'menue-wt' : 'main',
          language: props.currentLanguage
            ? props.currentLanguage.toUpperCase()
            : "DE",
        },
      }),
      name: "mainMenuQuery",
    }),
    graphql(configQuery, {
      options: (props) => ({
        variables: {
          name: props.microSite === "gdf" ? 'menu_config_gdf' : props.microSite === "kv" ? 'menu_config_kv' : props.microSite === "wt" ? 'menu_config_wt' : 'menu_cta'
        },
      }),
      name: "menuCtaConfig",
    }),
    graphql(menuQuery, {
      options: (props) => ({
        variables: {
          name: props.microSite === "gdf" ? 'often-clicked-gdf' : props.microSite === "kv" ? 'often-clicked-kv' : props.microSite === "wt" ? 'often-clicked-wt' : 'haeufig-geklickt',
          language: props.currentLanguage
            ? props.currentLanguage.toUpperCase()
            : "DE",
        },
      }),
      name: "oftenClickedMenuQuery",
    })
  )(SimpleNavigation)
);
