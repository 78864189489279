import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Slider from "react-slick";
import QuoteContent from "../zitat/quote-content";
import GalleryIndexInfo from "../../../gallery-index-info";

class ParagraphQuoteCarousel extends Component {
  slider = React.createRef();

  state = {
    slideIndex: 0,
    quotes: [],
    sliderSettings: {
      adaptiveHeight: false,
      arrows: true,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchMove: true,
      beforeChange: (current, next) => {
        this.setState({ slideIndex: next });
      },
      responsive: [
        {
          breakpoint: 786,
          settings: {
            adaptiveHeight: false
          },
        },
        {
          breakpoint: 550,
          settings: {
            adaptiveHeight: true
          },
        },
      ],
    }
  };

  componentDidMount() {
    if (this.props.content.fieldQuotes) {
      const filteredQuotes = this.props.content.fieldQuotes.filter(q => q.entity?.status === true);
      this.setState({ quotes: filteredQuotes });
    }
  }

  render() {
    const { quotes, slideIndex, sliderSettings } = this.state;
    const sectionClassNames = classNames({
      'paragraph paragraph-quote-carousel': true
    });

    return (
      <section className={sectionClassNames}>
        {quotes.length > 1 ? (
          <>
            <Slider {...sliderSettings} ref={this.slider}>
              {quotes.map((item, index) => (
                <div className="slide" key={index}>
                  <div className="container">
                    <div className="row">
                      <QuoteContent content={item.entity} inCarousel={true} />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <GalleryIndexInfo
              slideIndex={slideIndex}
              content={quotes}
              sliderRef={this.slider}
              type={"slick"}
            />
          </>
        ) : (
          quotes.length === 1 ? (
            <QuoteContent content={quotes[0].entity} inCarousel={false} />
          ) : null
        )}
      </section>
    );
  }
}

ParagraphQuoteCarousel.propTypes = {
  content: PropTypes.shape({
    __typename: PropTypes.string,
    entityBundle: PropTypes.string,
    entityId: PropTypes.string,
    fieldColorScheme: PropTypes.shape({
      __typename: PropTypes.string,
      entity: PropTypes.shape({
        __typename: PropTypes.string,
        fieldColorVariable: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
    fieldQuotes: PropTypes.arrayOf(
      PropTypes.shape({
        __typename: PropTypes.string,
        entity: PropTypes.shape({
          __typename: PropTypes.string,
          status: PropTypes.bool,
          fieldQuelle: PropTypes.string,
          fieldZitat: PropTypes.string,
          fieldImage: PropTypes.shape({
            __typename: PropTypes.string,
            entity: PropTypes.shape({
              __typename: PropTypes.string,
              fieldCredit: PropTypes.string,
              fieldMediaImage: PropTypes.shape({
                alt: PropTypes.string,
                title: PropTypes.string,
                style: PropTypes.shape({
                  __typename: PropTypes.string,
                  url: PropTypes.string,
                }),
              }),
            })
          })
        }),
      }),
    )
  }),
};

export default ParagraphQuoteCarousel;
