import React, { useState } from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";
import Play  from "./../../../icons/play";
import Vimeo from "@u-wave/react-vimeo";

const EmbeddedVideo = (props) => {
  const [showVideo, setShowVideo] = useState(false),
    [showInfo, setShowInfo] = useState(false);

  const mediaClassNames = classNames({
    "media-wrapper col-16": true,
    "show-infos": showInfo && !showVideo,
    "hide-infos": !showInfo && showVideo,
    "show-video": showVideo,
    "hide-video": !showVideo,
  });

  const getIdFromYoutube = (url) => {
    /* from: https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
    * Only get the id, because there can be several additions inside of the url, eg playlist IDs. But we keep the timestamp1
    */
    let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/,
      match = url.match(regExp);

    if (!match || match[7].length !== 11) return false;

    const urlObj = new URL(url);
    const timestamp = urlObj.searchParams.get("t");

    return timestamp ? `${match[7]}?start=${timestamp}` : `${match[7]}?`;
  };


  const thumbnail = props.content.fieldThumbnail ? props.content?.fieldThumbnail?.entity?.fieldMediaImage?.style?.url : props.thumbnail?.entity?.fieldMediaImage?.style?.url || props.content.thumbnail?.url,
    caption = props.caption || props.content?.fieldBildunterschrift;

  return (
    <article
      className={"embedded-video"}
    >
      <div
        className={mediaClassNames}
      >
        <div className="play-wrapper">
          <button
            className="play"
            onClick={() => setShowInfo(true)}
            tabIndex={0}
            role={"button"}
            aria-label={"play video"}
          >
            <Play/>
          </button>
          <div className="dsgvo-thumbnail youtube">
            <img src={thumbnail} alt={"Video Thumbnail"}/>
          </div>
          {showInfo && (
            <div className="privacy-info-banner">
              <div className="action-wrapper">
                <div className="accept-info">
                  Beim Abspielen dieses Videos werden Daten an YouTube
                  übertragen. Näheres dazu unter{" "}
                  <Link to={"/datenschutz"}>Datenschutz</Link>.
                </div>
                <button
                  tabIndex={0}
                  role="button"
                  aria-label="accept privacy infos"
                  className="btn btn-primary"
                  onClick={() => {
                    setShowVideo(true);
                    setShowInfo(false);
                  }}
                >
                  Alles klar
                </button>
              </div>
            </div>
          )}
        </div>

        {showVideo && (
          <div className="video-wrapper">
            <div className="iframe-positioner">
              <div className="iframe-wrap">
                {(props.content.fieldMediaOembedVideoEmbed.includes("youtu.be") || props.content.fieldMediaOembedVideoEmbed.includes("youtube")) &&
                  <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube-nocookie.com/embed/${getIdFromYoutube(props.content.fieldMediaOembedVideoEmbed)}${showVideo ? "&autoplay=1" : ""}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    rel={0}
                    allowFullScreen
                  />
                }
                {props.content.fieldMediaOembedVideoEmbed.includes("vimeo") &&
                  <Vimeo
                    video={props.content.fieldMediaOembedVideoEmbed}
                    responsive
                    autoplay
                    controls={true}
                  />
                }
              </div>
            </div>
          </div>
        )}

        {caption &&
          <>
            {props.fullSize ? (
              <div className="container">
                <div className="row">
                  <div className="col-16">
                    <span className="caption">{caption}</span>
                  </div>
                </div>
              </div>
            ) : (
              <span className="caption">{caption}</span>
            )}
          </>
        }
      </div>
    </article>
  )
};

export default EmbeddedVideo;
