import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

const NewsletterDOI = () => (
  <article className="newsletter-confirm">
    <section className="content-wrap">
      <section className="paragraph">
        <div className="container">
          <div className="row">
            <div className="col-16">
              <h1>
                <FormattedMessage id="newsletter.doi.headline" />
              </h1>
              <div className="text">
                <p>
                  <FormattedMessage id="newsletter.doi.body" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </article>
);

export default NewsletterDOI;
