import React from "react";
import { authenticationLoginAction } from "./redux-authentication";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { restHostBackend } from "../config";

const ButtonLogout = (props) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const logoutHandler = async () => {
    try {
      await axios.post("/api/v1/logout", {
        access_token: auth.userLoginData?.access_token,
      });
      dispatch(authenticationLoginAction({}));
      history.push("/");
    } catch (error) {
      console.error("Could not logout. Try again later.");
    }
  };

  if (!auth?.userLoginData?.logout_token) {
    return null;
  }

  return (
    <a
      role="button"
      onClick={logoutHandler}
      {...props}
      style={{ cursor: "pointer" }}
    >
      Logout
    </a>
  );
};

export default ButtonLogout;
