import useEmblaCarousel from "embla-carousel-react";
import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "react-use";

const SliderContext = createContext();

export const useSliderContext = () => {
  const context = useContext(SliderContext);
  return context;
};

const DEFAULT_OPTIONS = {
  loop: true,
  gap: 0,
};

const SliderProvider = ({ children, options }) => {
  const emblaOptions = { ...DEFAULT_OPTIONS, ...options };
  const [emblaRef, emblaApi] = useEmblaCarousel(emblaOptions);
  const { width } = useWindowSize();

  const [canScrollNext, setCanScrollNext] = useState(emblaApi?.canScrollNext() || false);
  const [canScrollPrev, setCanScrollPrev] = useState(emblaApi?.canScrollPrev() || false);

  useEffect(() => {
    if (emblaApi) {
      setCanScrollNext(emblaApi.canScrollNext());
      setCanScrollPrev(emblaApi.canScrollPrev());

      emblaApi.on("scroll", () => {
        setCanScrollNext(emblaApi.canScrollNext());
        setCanScrollPrev(emblaApi.canScrollPrev());
      });
    }
  }, [emblaApi, width]);

  const value = {
    emblaRef,
    emblaApi,
    emblaOptions,
    canScrollNext,
    canScrollPrev
  };

  return (
    <SliderContext.Provider value={value}>{children}</SliderContext.Provider>
  );
};

SliderProvider.propTypes = {
  children: PropTypes.node.isRequired,
  options: PropTypes.shape({
    loop: PropTypes.bool,
    gap: PropTypes.any,
    width: PropTypes.any,
  }),
};

export default SliderProvider;