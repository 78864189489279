import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import GalleryArrow from "../icons/gallery-arrow";
import { useSliderContext } from "./context";

export const Slider = ({ children }) => {
  const { emblaRef, emblaOptions, emblaApi, canScrollPrev, canScrollNext } =
    useSliderContext();

  const style = {};
  if (emblaOptions.gap) {
    style["--embla-gap"] = emblaOptions.gap;
  }
  if (emblaOptions.width) {
    style["--embla-width"] = emblaOptions.width;
  }

  const handlePrev = () => emblaApi.scrollPrev();
  const handleNext = () => emblaApi.scrollNext();

  return (
    <>
      {canScrollPrev && (
        <div
          className="embla__click-overlay prev"
          role="button"
          onClick={handlePrev}
        />
      )}
      <div className="embla" ref={emblaRef} style={style}>
        <div className="embla__container">{children}</div>
      </div>
      {canScrollNext && (
        <div
          className="embla__click-overlay next"
          role="button"
          onClick={handleNext}
        />
      )}
    </>
  );
};

export const Slide = ({ children }) => {
  return <div className="embla__slide">{children}</div>;
};

export const SliderControls = () => {
  const { emblaApi } = useSliderContext();

  const handlePrev = () => emblaApi.scrollPrev();
  const handleNext = () => emblaApi.scrollNext();

  return (
    <>
      <button
        className="prev embla-arrow"
        onClick={handlePrev}
      >
        <GalleryArrow type={"previous"} followMouse={false}/>
        <span className="sr-only">Previous</span>
      </button>

      <button
        className="next embla-arrow"
        onClick={handleNext}
      >
        <GalleryArrow type={"next"} followMouse={false} />
        <span className="sr-only">Next</span>
      </button>
    </>
  );
};

Slider.propTypes = {
  children: PropTypes.node.isRequired,
  options: PropTypes.shape({
    loop: PropTypes.bool,
  }),
};