const restHost = 'https://hmdk-cs.dev.e-fork.net',
  restHostBackend = 'https://hmdk-cs.dev.e-fork.net',
  graphQlEndpoint = '/graphql',
  drupalFormat = 'YYYY-MM-DDTHH:mm:ss',
  self = 'https://hmdk-vs.dev.e-fork.net/';

const configIDs = {
  "hmdk": "1",
  "general": "6"
}

const msBaseURLs = {
  gdf: "https://hmdk-gdf-vs.dev.e-fork.net",
  kv: "https://hmdk-kv-vs.dev.e-fork.net",
  wt: "https://hmdk-wt-vs.dev.e-fork.net",
  hmdk: "https://hmdk-vs.dev.e-fork.net",
};

export { restHost, restHostBackend, graphQlEndpoint, self, drupalFormat, configIDs, msBaseURLs };
