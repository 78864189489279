import React, {useState, useEffect} from "react";
import groupBy from "lodash.groupby";
import TeaserKvCategory from "../../../teaser-base/kv-category/teaser-kv-category";
import OverviewProvider from "./store/overview-provider";
import useOverviewContext from "./store/use-overview-context";
import PropTypes from "prop-types";
import ParagraphNumberFacts from "../number-facts/paragraph-number-facts";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  currentLanguage: reduxStore.i18n.currentLanguage,
});

const ArtistOverviewCategories = (props) => {
  const { taxonomies, preparedTaxonomies, getPreparedTaxonomies } = useOverviewContext();

  useEffect(() => {
    if (taxonomies) {
      getPreparedTaxonomies(taxonomies);
    }
  }, []);

  return(
    <section className="artist-overview-categories">
      <div className="container-fluid">
        <div className="row">
          <div className="col-16">
            {preparedTaxonomies && Object.keys(preparedTaxonomies).map((keyName, index) =>
              <React.Fragment key={index}>
                <TeaserKvCategory
                  content={preparedTaxonomies[keyName]}
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const ParagraphArtistOverviewCategories = ({ taxonomies }) => {
  return (
    <OverviewProvider taxonomies={taxonomies}>
      <ArtistOverviewCategories />
    </OverviewProvider>
  );
};
ParagraphArtistOverviewCategories.propTypes = {
  content: PropTypes.shape({

  }),
};
export default ParagraphArtistOverviewCategories;