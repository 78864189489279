import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import LoadingIndicator from "../general-components/loading-indicator";
import infoscreenQuery from "./inforscreen.graphql";
import Error404 from "../error-404";
import ContentBase from "../general-components/content-base/content-base";
import NodeWrapper from "../node-wrapper";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  pagerFullPageContextId: reduxStore.appStore.pagerFullPageContextId,
  pagerConfig: reduxStore.appStore.pagerFullPages,
  microSite: reduxStore.appStore.microSite,
  microSiteFrontPage: reduxStore.appStore.microSiteFrontPage,
  auth: reduxStore.auth,
});

class Inforscreen extends Component {
  render() {
    return (
      <NodeWrapper
        entity={this.props.data?.route?.entity}
        loading={this.props.data?.loading}
        route={this.props.data?.route}
      >
        {this.props.data.route?.entity?.fieldInfoscreenContent && (
          <ContentBase
            content={[this.props.data.route?.entity?.fieldInfoscreenContent]}
          />
        )}
      </NodeWrapper>
    );
  }
}

Inforscreen.propTypes = {
  auth: PropTypes.object,
  data: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.array,
  pagerFullPageContextId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
};

export default connect(mapStateToProps)(
  graphql(infoscreenQuery, {
    options: (props) => ({
      variables: {
        path: props.location.pathname,
      },
    }),
  })(withRouter(Inforscreen))
);
