import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ButtonLogout from "./button-logout";

const mapStateToProps = (reduxStore) => ({ auth: reduxStore.auth });

class UserStatusBlock extends Component {
  render() {
    return (
      <li className="logout">
        <ButtonLogout className="btn" />
      </li>
    );
  }
}

UserStatusBlock.propTypes = {
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(UserStatusBlock);
