import moment from "moment";
import { drupalFormat, self } from "../config";

/**
 *
 * @param {Object} event.starTime
 * @param {String} location
 * @param {String} title
 * @param {String} description
 * @returns
 */
const getEncodedIcalUrl = (event) => {
  const startTimestamp =
    moment(event.startDate, "YYYY-MM-DD HH:mm").utc().format("YYYYMMDDTHHmmss") + "Z";
  const endTimestamp =
    moment(event.endDate, "YYYY-MM-DD HH:mm").utc().format("YYYYMMDDTHHmmss") + "Z";
  const currentTimestamp = moment().format("YYYYMMDDTHHmmss");

  const icalBaseObject = {
    UID: currentTimestamp + "@" + self,
    CREATED: currentTimestamp,
    DTSTAMP: startTimestamp,
    DTSTART: startTimestamp,
    ...(event.endDate && moment(event.endDate).isAfter(event.startDate) && { DTEND: endTimestamp }),
    SUMMARY: event.title,
    DESCRIPTION: event.description.replace(/\n/g, '\\n').replace(/<[^>]*>/g, ''),
    LOCATION: event.location,
  };

  const icalString =
    [
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      "PRODID:TEST",
      "BEGIN:VEVENT",
      `UID:${icalBaseObject.UID}`,
      `CREATED:${icalBaseObject.CREATED}`,
      `DTSTAMP:${icalBaseObject.DTSTAMP}`,
      `DTSTART:${icalBaseObject.DTSTART}`,
      icalBaseObject.DTEND && (icalBaseObject.DTSTART !== icalBaseObject.DTEND) &&`DTEND:${icalBaseObject.DTEND}`,
      `SUMMARY:${icalBaseObject.SUMMARY}`,
      `DESCRIPTION:${icalBaseObject.DESCRIPTION}`,
      `LOCATION:${icalBaseObject.LOCATION}`,
      "END:VEVENT",
      "END:VCALENDAR",
    ].join("\r\n") + "\r\n";

  return "data:text/calendar," + encodeURIComponent(icalString);
};

export default getEncodedIcalUrl;
