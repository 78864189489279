import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import ExecutionEnvironment from "exenv";
import { Redirect, withRouter } from "react-router-dom";
import LoadingIndicator from "../general-components/loading-indicator";
import ContentBase from "../general-components/content-base/content-base";
import landingPageQuery from "./landingpage.graphql";
import PagerFullPage from "../general-components/pager-full-page";
import { i18nActionHelper } from "../i18n/i18n";
import Error404 from "../error-404";
import NodeWrapper from "../node-wrapper";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  pagerFullPageContextId: reduxStore.appStore.pagerFullPageContextId,
  pagerConfig: reduxStore.appStore.pagerFullPages,
  microSite: reduxStore.appStore.microSite,
  microSiteFrontPage: reduxStore.appStore.microSiteFrontPage,
  auth: reduxStore.auth,
});

class Landingpage extends Component {
  componentDidMount() {
    i18nActionHelper(this.props, this.props.dispatch);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.data.route) !==
      JSON.stringify(prevProps.data.route)
    ) {
      i18nActionHelper(this.props, this.props.dispatch);
    }
  }

  render() {
    if (
      !this.props?.auth?.userLoginData.logout_token &&
      ExecutionEnvironment.canUseDOM &&
      this.props.data.route?.entity?.fieldInternal
    ) {
      return <Redirect to="/" />;
    }

    return (
      <NodeWrapper
        entity={this.props.data?.route?.entity}
        loading={this.props.data?.loading}
        route={this.props.data?.route}
      >
        {this.props.data?.route?.entity.fieldModules && (
          <ContentBase
            content={this.props.data.route.entity.fieldModules}
            node={this.props.data.route.entity}
          />
        )}

        {this.props.pagerFullPageContextId &&
          this.props.pagerConfig.filter(
            (config) => config.id === this.props.pagerFullPageContextId
          ).length > 0 && (
            <PagerFullPage
              config={
                this.props.pagerConfig.filter(
                  (config) => config.id === this.props.pagerFullPageContextId
                )[0]
              }
              currentId={this.props.data.route?.entity.entityId}
            />
          )}
      </NodeWrapper>
    );
  }
}

Landingpage.propTypes = {
  auth: PropTypes.object,
  data: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.array,
  pagerFullPageContextId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
};

export default connect(mapStateToProps)(
  graphql(landingPageQuery, {
    options: (props) => ({
      variables: {
        path:
          props.location.pathname === "/" &&
          props.microSite &&
          props.microSiteFrontPage !== ""
            ? props.microSiteFrontPage
            : props.location.pathname,
      },
    }),
  })(withRouter(Landingpage))
);
