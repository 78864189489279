import React, {useState, useEffect} from "react";
import artistTaxonomyTermQuery from "artist-taxonomy-query.graphql";
import { graphql } from "@apollo/client/react/hoc";
import { useHistory, useLocation } from "react-router-dom";
import ParagraphArtistOverviewCategories from "./paragraph-artist-overview-categories";
import ParagraphArtistOverviewArtists from "./paragraph-artist-overview-artists";
import OverviewProvider from "./store/overview-provider";
import useOverviewContext from "./store/use-overview-context";
import PropTypes from "prop-types";


const getParentWhenUrlOpened = (taxonomies, selectedTaxonomyId) => {
  const content = taxonomies?.taxonomyTermQuery?.entities;

  let returnedTaxonomy = {};

  if (content) {
    content.map((taxonomy) => {
      if (taxonomy?.tid?.toString() === selectedTaxonomyId) {
        returnedTaxonomy = taxonomy;
      }
    });
  }

  return returnedTaxonomy;
}

const ArtistOverview = (props) => {
  const location = useLocation();

  // Get relevant values from the OverviewContext.
  const { taxonomyFilter, setTaxonomyFilter, taxonomies, getPreparedTaxonomies, preparedTaxonomies, groupedTaxonomies } = useOverviewContext();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search),
      taxonomyFilter = getParentWhenUrlOpened(taxonomies, urlParams.get("id"));

    setTaxonomyFilter(taxonomyFilter);
  }, [location.search]);

  useEffect(() => {
    if (taxonomies) {
      getPreparedTaxonomies(taxonomies);
    }
  }, [taxonomies]);

  return(
    <section className="paragraph paragraph-artist-overview">
      {(Object.keys(taxonomyFilter).length === 0 && props?.content?.fieldArtistType === "artist") ? (
        <ParagraphArtistOverviewCategories
          taxonomies={taxonomies}
        />
      ) : (
        <ParagraphArtistOverviewArtists
          filterByTaxonomy={taxonomyFilter}
          // Needs to be send via props to use it in filter function
          groupedTaxonomies={groupedTaxonomies}
          preparedTaxonomies={preparedTaxonomies}
          type={props?.content?.fieldArtistType}
        />
      )}
    </section>
  );
};


const ParagraphArtistOverview = ({ taxonomies, content }) => {
  return (
    <OverviewProvider taxonomies={taxonomies}>
      <ArtistOverview content={content} />
    </OverviewProvider>
  );
};


export default (
  graphql(artistTaxonomyTermQuery, {
    name: "taxonomies",
    options: (props) => ({
      variables: {
        kvEnabled: props.content?.fieldArtistType === "artist",
        ensembleEnabled: props.content?.fieldArtistType === "ensembles"
      },
    }),
  })(ParagraphArtistOverview)
);