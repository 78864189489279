/**
 * Redirect config for main site and microsites.
 *
 * Only add items, if you want to redirect a bundle from one microsite to another.
 * Non-existing bundles will be ignored and therefore not redirected.
 * redirectTo must be a valid microsite key or false if no redirect should occur.
 *
 * @see https://images.news18.com/ibnlive/uploads/2021/12/spiderman-meme.png (spiderman meme)
 *
 * @param {array} siteChannel - The site channel(s) of the entity (if available).
 * @param {string} microsite - The microsite shortcode.
 * @returns {Object} - The redirect object.
 */
const MS_REDIRECTS = (siteChannel = ["hmdk"], microsite) => ({
  /**
   * Routing based on Content Type
   */
  routes: [
    {
      bundle: "landingpage",
      redirectTo: "hmdk",
    },
    {
      bundle: "landingpage_wt",
      redirectTo: "wt",
    },
    {
      bundle: "landingpage_kv",
      redirectTo: "kv",
    },
    {
      bundle: "landingpage_gdf",
      redirectTo: "gdf",
    },
    {
      bundle: "veranstaltung",
      redirectTo: (() => {
        if (siteChannel && !siteChannel.includes(microsite)) {
          return siteChannel[0];
        }
        return false;
      })(),
    },
    {
      bundle: "news",
      redirectTo: (() => {
        if (siteChannel && !siteChannel.includes(microsite)) {
          return siteChannel[0];
        }
        return false;
      })(),
    },
    {
      bundle: "person",
      redirectTo: (() => {
        if (siteChannel && !siteChannel.includes(microsite)) {
          return siteChannel[0];
        }
        return false;
      })(),
    },
    {
      bundle: "course",
      redirectTo: "hmdk",
    },
    {
      bundle: "projekt",
      redirectTo: "hmdk",
    },
    {
      bundle: "ensemble",
      redirectTo: "kv",
    },
    {
      bundle: "artist",
      redirectTo: "kv",
    },
    {
      bundle: "infoscreen",
      redirectTo: "hmdk",
    },
  ]
});

export default MS_REDIRECTS;