import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import {Redirect, Route, useLocation} from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingIndicator from "./general-components/loading-indicator";
import Error404 from "./error-404";
import { msBaseURLs } from "./config";
import MS_REDIRECTS from "./ms-redirects";
import {FormattedMessage} from "react-intl";

const NodeWrapper = ({ entity, route, loading, children }) => {
  const { pathname } = useLocation();
  const microsite = useSelector((state) => state.appStore.microSite) || "hmdk";

  // Return 404 if route is not found.
  if (!loading && route === null) {
    return <Error404 />;
  }

  // Return the loading indicator as long as query is not completed.
  if (!route && !entity) {
    return <LoadingIndicator fullPage={true} />;
  }

  // Get the entity bundle and status.
  const entityBundle = entity.entityBundle;
  const published = entity.status ? "node-published" : "node-unpublished";

  // Get the site channel if available.
  const siteChannel = entity.fieldSiteChannel || ["hmdk"];

  // Get the redirects for the current (micro)site.
  const redirects = MS_REDIRECTS(siteChannel, microsite);

  const currentRedirect = redirects.routes?.find(
    (item) => (item.bundle === entityBundle) && (item.redirectTo !== microsite)
  )?.redirectTo;

  // Redirect ms front pages to "/", all other pages to its corresponding baseUrl end correct further path.
  const redirectUrl = /^(wt|gdf|kv)-start$/.test(pathname.replace(/^\/+/, ""))
    ? `${msBaseURLs[currentRedirect]}/`
    : `${msBaseURLs[currentRedirect]}${pathname}`;

  // Redirect to the correct microsite if necessary.
  if (currentRedirect) {
    // Visible Redirect via Frontend
    if (typeof window !== "undefined") {
      window.location.replace(redirectUrl);
      return (
        <div className="redirect-to">
          <div className="container">
            <div className="row">
              <div className="col-16">
                <h2><FormattedMessage id={`redirect.${currentRedirect}`}/></h2>
              </div>
            </div>
          </div>
          <LoadingIndicator fullPage={true} />
        </div>
      );
    } else {
      // "Invisible" Redirect for SSR and Google, also sending 307.
      return (
        <Route
          render={({ staticContext }) => {
            if (staticContext) staticContext.statusCode = 307;
            return (
              <Redirect to={redirectUrl} />
            );
          }}
        />
      );
    }
  }

  let pageTitle = 'HMDK Stuttgart';

  if (microsite) {
    switch (microsite) {
      case "wt": {
        pageTitle = 'Wilhelma Theater Stuttgart';
      }
      break;
      case "kv": {
        pageTitle = 'Künstlervermittlung HMDK Stuttgart';
      }
      break;
      case "gdf": {
        pageTitle = 'GdF HMDK Stuttgart';
      }
    }
  }

  const nodeTitle = entity?.fieldDisplayedTitle || entity.entityLabel;

  return (
    <article
      className={`node-${entity.entityBundle} ${
        pathname === "/" ? "frontpage" : ""
      } node-${entity.entityId} node-full-page ${published}`}
    >
      <Helmet>
        <title>{`${pageTitle} | ${nodeTitle}`}</title>
      </Helmet>

      {children}
    </article>
  );
};

NodeWrapper.propTypes = {
  entity: PropTypes.object,
  loading: PropTypes.bool,
  route: PropTypes.object,
  children: PropTypes.node,
};

export default NodeWrapper;
