import React, { useEffect, useState } from "react";
import queryString from "query-string";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { authenticationLoginAction } from "./redux-authentication";
import { cache } from "../cache";

export const LogoutLogic = () => {
  const auth = useSelector((state) => state.auth);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();

  // Did mount equivalent.
  useEffect(() => {
    const queryParams = queryString.parse(location.search);

    if (queryParams.action && queryParams.action === "logout") {
      setSubmitting(true);

      axios
        .post(
          `/api/v1/logout`,
          {
            access_token: auth.userLoginData.access_token,
          },
          {
            headers: {
              "Content-type": "application/json",
            },
            withCredentials: false,
          }
        )
        .then(() => {
          dispatch(authenticationLoginAction({}));
          cache.reset();
          setSuccess(true);

          setTimeout(() => {
            setSuccess(false);
          }, 1500);
        })
        .catch((error) => {
          setError(error.response.data.message);

          dispatch(authenticationLoginAction({}));
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  }, []);

  // @todo translate!
  if (success) {
    return (
      <div className="modal">
        <div className="box">
          <div className="text body-s">Sie wurden erfolgreich ausgeloggt.</div>
        </div>
      </div>
    );
  }

  if (submitting) {
    return (
      <div className="modal">
        <div className="box">
          <div className="text body-s">
            Sie werden ausgeloggt, bitte haben sie einen Augenblick geduld.
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="modal">
        <div className="box">
          <div className="text body-s">{error}</div>
        </div>
      </div>
    );
  }

  return null;
};
