import React from "react";
import FieldTime from "../general-components/date-time/field-time";
import moment from "moment";
import {FormattedMessage} from "react-intl";

export const EventDate = (props) => {

  let content = props.content?.entity || props.content;
  let eventPassed = moment().diff(moment.unix(content.fieldDate?.value).local()) > 0,
    endValueSameDay = content.fieldDate.endValue && moment.unix(content.fieldDate.endValue).local().format("DD.MM.YYYY") === moment.unix(content.fieldDate.value).local().format("DD.MM.YYYY"),
    showEndValue = content.fieldDate.endValue && moment.unix(content.fieldDate.endValue).local().format("HH:mm") !== moment.unix(content.fieldDate.value).local().format("HH:mm"),
    showEndHours = showEndValue && (moment.unix(content.fieldDate.endValue).local().format("HH:mm") !== "23:59" && moment.unix(content.fieldDate.endValue).local().format("HH:mm") !== "00:00");

  if (content) {
    return(
      <>
        {content.fieldDate && content.fieldDate.value &&
          <>
            <FieldTime
              date={content.fieldDate.value}
              format={`DD.MM${content.fieldDate.endValue ? '.' : '.YYYY'}${endValueSameDay ? 'YYYY ' : ''}`}
              timestamp={true}
            />
            {content.fieldDate && moment.unix(content.fieldDate.value).local().format("HH:mm") !== "00:00" &&
              <>
                <FieldTime
                  date={content.fieldDate.value}
                  format={" HH:mm "}
                  timestamp={true}
                />
                <FormattedMessage id="time.hours"/>
              </>
            }
          </>
        }
        {content.fieldDate && content.fieldDate.endValue && showEndValue &&
          <>
            {!endValueSameDay ? (
              <>
                {" – "}
                <FieldTime
                  date={content.fieldDate.endValue}
                  format={`DD.MM.YYYY`}
                  timestamp={true}
                />
              </>
            ) : (
              <>
                {(showEndValue && showEndHours) &&
                  <>
                    {/* Show Divider when end value is on same day, but has end time */}
                    {" – "}
                  </>
                }
              </>
            )}
            {content.fieldDate && showEndValue && showEndHours &&
              <>
                <FieldTime
                  date={content.fieldDate.endValue}
                  format={" HH:mm "}
                  timestamp={true}
                />
                <FormattedMessage id="time.hours"/>
              </>
            }
          </>
        }
      </>
    );
  }
};

export default EventDate;