import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import LoadingIndicator from "../general-components/loading-indicator";
import ContentBase from "../general-components/content-base/content-base";
import personPageQuery from "./person.graphql";
import PagerFullPage from "../general-components/pager-full-page";
import { withRouter } from "react-router-dom";
import { i18nActionHelper } from "../i18n/i18n";
import Image from "../general-components/image/image";
import {FormattedMessage} from "react-intl";
import Arrow from "../general-components/icons/arrow";
import PersonName from "./person-name";
import VcardSaveButton from "./vcard-save-button";
import PersonClassification from "./person-classification";
import Error404 from "../error-404";
import WriteMail from "../general-components/write-mail/write-mail";
import useScreenSize from "../hooks/use-screen-size";
import UseScreenSize from "../lib/use-screen-size";
import CheckForChildren from "../lib/check-for-children";
import NodeWrapper from "../node-wrapper";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  pagerFullPageContextId: reduxStore.appStore.pagerFullPageContextId,
  pagerConfig: reduxStore.appStore.pagerFullPages,
});

class Person extends Component {
  componentDidMount() {
    i18nActionHelper(this.props, this.props.dispatch);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.data.route) !==
      JSON.stringify(prevProps.data.route)
    ) {
      i18nActionHelper(this.props, this.props.dispatch);
    }
  }

  render() {
    const entity = this.props.data?.route?.entity;

      return (
        <NodeWrapper entity={this.props.data?.route?.entity} loading={this.props.data?.loading} route={this.props.data?.route}>
          <div className="container">
            <div className="row">
              <div className={`col-16 col-md-7 main-col ${entity?.fieldColorScheme ? entity?.fieldColorScheme.entity?.fieldColorVariable : 'theme-1'}`}>
                <div className={`top-section ${entity?.fieldBildWCaption ? 'with-image' : 'without-image'}`}>
                  {entity?.fieldBildWCaption &&
                    <div className={"image-wrapper"}>
                      <Image
                        data={entity?.fieldBildWCaption.entity?.fieldMediaImage}
                        style="styleSquare"
                        nodeTitle={entity?.title}
                        credit={entity?.fieldBildWCaption.entity?.fieldCredit}
                        caption={
                          entity?.fieldBildWCaption.defaultCaption === false
                            ? entity?.fieldBildWCaption.customCaption
                            : null
                        }
                        />
                    </div>
                  }
                  <h1 className="headline-m">
                    <PersonName content={entity}/>
                  </h1>
                  <PersonClassification content={entity}/>
                </div>
                <div className="bottom-section">
                  {entity?.fieldBildWCaption && entity?.fieldBildWCaption.entity?.fieldCredit &&
                    <span>Foto: {entity?.fieldBildWCaption.entity?.fieldCredit}</span>
                  }
                </div>
              </div>

              <div className="col-16 col-md-9 offset-md-0 sidebar">

                <h2 className="headline-s">
                  {entity?.entityBundle === "artist" ? (
                    <FormattedMessage id={"person.profile"}/>
                  ) : (
                    <FormattedMessage id={"person.contact"}/>
                  )}
                </h2>

                <div className="row infos">
                  <div className="col-16">
                    <CheckForChildren classes={"contact-details-wrapper texts"}>
                      {entity?.fieldRepertoire &&
                        <div className="flex-wrap">
                          <span className="label bold">
                            <FormattedMessage id={"person.repertoire"} />
                          </span>
                          <div
                            className="text"
                            dangerouslySetInnerHTML={{
                              __html: entity?.fieldRepertoire.processed
                            }}
                            />
                        </div>
                      }

                      {entity?.fieldEnsembles &&
                        <div className="flex-wrap">
                          <span className="label bold">
                            <FormattedMessage id={"person.ensembles"} />
                          </span>
                          <div
                            className="text"
                            dangerouslySetInnerHTML={{
                              __html: entity?.fieldEnsembles.processed
                            }}
                          />
                        </div>
                      }
                      {entity?.fieldProfile &&
                        <div className="flex-wrap profile">
                          <span className="label bold">
                            <FormattedMessage id={"person.profile"} />
                          </span>
                          <div
                            className="text"
                            dangerouslySetInnerHTML={{
                              __html: entity?.fieldProfile.processed
                            }}
                            />
                        </div>
                      }
                    </CheckForChildren>

                    {!!entity?.fieldHasMail && (
                      <div className="contact-details-wrapper e-mail links">
                        <div className="label margin-bottom">
                          <WriteMail content={entity} />
                        </div>
                      </div>
                    )}

                    <CheckForChildren classes={"contact-details-wrapper telephone"}>
                      {entity?.fieldTelefon && (
                        <div className="flex-wrap">
                          <span className="label">Tel.</span>
                          <a href={`tel:${entity?.fieldTelefon}`} className="value">{entity?.fieldTelefon}</a>
                        </div>
                      )}
                      {entity?.fieldMobil && (
                        <div className="flex-wrap">
                          <span className="label">Mobil</span>
                          <span className="value">{entity?.fieldMobil}</span>
                        </div>
                      )}
                      {entity?.fieldFax && (
                        <div className="flex-wrap">
                          <span className="label">Fax</span>
                          <span className="value">{entity?.fieldFax}</span>
                        </div>
                      )}
                    </CheckForChildren>

                    <CheckForChildren classes="contact-details-wrapper location-infos">
                      {entity?.fieldRoom && (
                        <div className="flex-wrap">
                          <span className="label bigger"><FormattedMessage id="room" /></span>
                          <span className="value">{entity?.fieldRoom}</span>
                        </div>
                      )}
                      {entity?.fieldBuilding && (
                        <div className="flex-wrap">
                          <span className="label bigger"><FormattedMessage id="building" /></span>
                          <span className="value">{entity?.fieldBuilding}</span>
                        </div>
                      )}
                      {entity?.fieldOfficeHours && (
                        <div className="flex-wrap">
                          <span className="label bigger"><FormattedMessage id="officehours" /></span>
                          <span className="value">{entity?.fieldOfficeHours}</span>
                        </div>
                      )}
                    </CheckForChildren>

                    <CheckForChildren classes={"contact-details-wrapper links"}>
                      {entity?.fieldInstagram && (
                        <div className="label">
                          <a href={entity?.fieldInstagram.url.path} target={"_blank"}>
                            <Arrow orientation={"up-right"} width={"1"}/> Instagram
                          </a>
                        </div>
                      )}
                      {entity?.fieldTwitter && (
                        <div className="label">
                          <a href={entity?.fieldTwitter.url.path} target={"_blank"}>
                            <Arrow orientation={"up-right"} width={"1"}/> X
                          </a>
                        </div>
                      )}
                      {entity?.fieldFacebook && (
                        <div className="label">
                          <a href={entity?.fieldFacebook.url.path} target={"_blank"}>
                            <Arrow orientation={"up-right"} width={"1"}/> Facebook
                          </a>
                        </div>
                      )}
                      {entity?.fieldYoutube && (
                        <div className="label">
                          <a href={entity?.fieldYoutube.url.path} target={"_blank"}>
                            <Arrow orientation={"up-right"} width={"1"}/> YouTube
                          </a>
                        </div>
                      )}
                      {entity?.fieldWebseite && entity?.fieldWebseite?.map((item, index) => (
                        <div className="label" key={index}>
                          <a href={item?.url.path} target={"_blank"}>
                            <Arrow orientation={"up-right"} width={"1"}/>
                            {item.title ? (
                              <>
                                {item.title}
                              </>
                            ) : (
                              <>
                                {item.url.path.replace("https://", "").replace("http://", "")}
                              </>
                            )}
                          </a>
                        </div>
                      ))}
                    </CheckForChildren>

                    {!!entity?.fieldHasMail &&
                      <div className="contact-details-wrapper save-contact">
                        <VcardSaveButton content={entity} />
                      </div>
                    }
                  </div>
                </div>

                <UseScreenSize size={"lg"} operator={false}>
                  {entity?.fieldBeschreibung &&
                    <div className={"biography"}>
                      <div
                        className="text"
                        dangerouslySetInnerHTML={{
                          __html: entity?.fieldBeschreibung.processed
                        }}
                        />
                    </div>
                  }
                  <div className="modules-wrapper">
                    {entity?.fieldModules && (
                      <ContentBase content={entity?.fieldModules} />
                    )}
                  </div>
                </UseScreenSize>
              </div>


                <UseScreenSize size={"lg"} operator={true}>
                  <div className="col-16 mobile-module-wrapper">
                    {entity?.fieldBeschreibung &&
                      <div className={"biography"}>
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{
                            __html: entity?.fieldBeschreibung.processed
                          }}
                          />
                      </div>
                    }
                    <div className="modules-wrapper">
                      {entity?.fieldModules && (
                        <ContentBase content={entity?.fieldModules} />
                      )}
                    </div>
                  </div>
                </UseScreenSize>
            </div>
          </div>

          {this.props.pagerFullPageContextId &&
            this.props.pagerConfig.filter(
              (config) => config.id === this.props.pagerFullPageContextId
            ).length > 0 && (
              <PagerFullPage
              config={
                this.props.pagerConfig.filter(
                    (config) => config.id === this.props.pagerFullPageContextId
                  )[0]
                }
                currentId={entity?.entityId}
              />
            )}
      </NodeWrapper>
    );
  }
}

Person.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.array,
  pagerFullPageContextId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
};

export default graphql(personPageQuery, {
  options: (props) => ({
    variables: { path: props.location.pathname },
  }),
})(connect(mapStateToProps)(withRouter(Person)));
