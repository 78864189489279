import React from "react";
import FileSaver from "file-saver";
import {FormattedMessage} from "react-intl";
import getMailQuery from "../general-components/write-mail/get-mail.graphql";
import {withApollo} from "@apollo/client/react/hoc";

const VcardSaveButton = ({ client, content }) => {
  const buildVCard = (e, content, mail) => {

    let noteParts = [];

    if (content.fieldPositionFree) {
      noteParts.push(content.fieldPositionFree);
    }

    if (content.fieldSubject && content.fieldSubject.length > 0 && content.fieldSubject[0]?.entity?.entityLabel) {
      noteParts.push(`Fach: ${content.fieldSubject[0]?.entity?.entityLabel}`);
    }

    if (content.fieldRoom) {
      noteParts.push(`Raum: ${content.fieldRoom}`);
    }

    if (content.fieldOfficeHours) {
      noteParts.push(`Sprechzeiten: ${content.fieldOfficeHours}`);
    }

// Kombinieren der einzelnen Teile mit einem <br> und sicherstellen, dass keine Leerzeichen entstehen
    let note = noteParts.join("\\n");

    let contact = {
      lastName: content.fieldSurname,
      firstName: content.fieldFirstName,
      title: content.fieldAcademicTitle,
      email: mail,
      tel: content.fieldTelefon,
      mobile: content.fieldMobil,
      note: note
    }

    //https://codesandbox.io/s/x986yly68w?file=/src/components/VCardFileCreator/index.js:222-230
    // I know, this looks ugly formatted, however it only works like that.
    e.preventDefault();
    var file = new Blob(
      [
        `BEGIN:VCARD
VERSION:3.0
N:${contact.lastName};${contact.firstName}
FN:${contact.firstName} ${contact.lastName}
${contact.title ? `TITLE:${contact.title}` : ''}
${contact.email ? `EMAIL;TYPE=INTERNET,PREF:${contact.email}` : ''}
${contact.tel ? `TEL;TYPE=WORK:${contact.tel}` : ''}
${contact.mobile ? `TEL;TYPE=CELL,VOICE,PREF:${contact.mobile}` : ''}
${contact.note ? `NOTE:${contact.note.replace(/\n/g, '\\n').replace(/<[^>]*>/g, '')}` : ''}
END:VCARD`
      ],
      { type: "text/vcard;charset=utf-8" }
    );

    let a = document.createElement("a");

    a.download = `${contact.firstName}${contact.lastName}.vcf`;
    a.href = URL.createObjectURL(file);

    let reader = new FileReader();

    if (navigator.userAgent.match("CriOS")) {
      reader.onloadend = e => {
        window.open(reader.result);
      };
      reader.readAsDataURL(file);
    } else if (navigator.userAgent.match(/iPad|iPhone|Android/i)) {
      reader.onload = e => {
        window.location.href = reader.result;
      };
      reader.readAsDataURL(file);
    } else {
      FileSaver.saveAs(
        file,
        `${contact.title ? contact.title : ''}${contact.firstName}${contact.lastName}.vcf`,
        true
      );
    }
  }

  const createVCS = (e, content) => {

    if (content.fieldHasMail) {
      const url = content?.path?.alias || content?.entityUrl?.path;
      let mail = "";

      client.query({
        query: getMailQuery,
        variables: { path: url }
      }).then(response => {
        mail = response?.data?.route?.entity?.fieldMail;

        buildVCard(e, content, mail);
      });
    } else {
      buildVCard(e, content, false);
    }
  }

  return(
    <button className={"btn btn-s btn-save"} onClick={(e) => createVCS(e, content)}>
      <FormattedMessage id="contact.save_contact" />
    </button>
  );
}

export default withApollo(VcardSaveButton);