import React, { useEffect, useState } from "react";
import queryString from "query-string";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

import { authenticationLoginAction } from "./redux-authentication";
import { cache } from "../cache";

export const LoginLogic = () => {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [redirectUrlFromSettings, setRedirectUrlFromSettings] =
    useState("/HMDK-intern");

  const location = useLocation();
  const dispatch = useDispatch();
  const mainSettings = useSelector((state) => state.appStore.mainSettings);

  const query = queryString.parse(location.search);

  // Did mount equivalent.
  useEffect(() => {
    const queryParams = queryString.parse(location.search);

    if (
      queryParams.logout_token &&
      queryParams.access_token &&
      queryParams.csrf_token
    ) {
      setSubmitting(true);

      axios
        .post(
          `/api/v1/saml-login`,
          {
            access_token: queryParams.access_token,
            csrf_token: queryParams.csrf_token,
            current_user_name: queryParams.current_user_name,
            current_user_roles: queryParams.current_user_roles,
            current_user_uid: queryParams.current_user_uid,
            logout_token: queryParams.logout_token,
          },
          {
            headers: {
              "Content-type": "application/json",
            },

            withCredentials: false,
          }
        )
        .then((response) => {
          console.log("success");

          dispatch(authenticationLoginAction(response.data));

          setTimeout(() => {
            setSuccess(true);
            setSubmitting(false);
            cache.reset();
          }, 1500);
        })
        .catch((error) => {
          setError(error.response.data.error);

          setSubmitting(false);
        });
    }
  }, []);

  useEffect(() => {
    if (mainSettings.fieldHomepageInternal?.url?.path) {
      setRedirectUrlFromSettings(mainSettings.fieldHomepageInternal?.url?.path);
    }
  }, [mainSettings]);

  // @todo translate!
  if (submitting) {
    return (
      <div className="modal">
        <div className="box">
          <div className="text body-s">
            Sie werden eingeloggt, bitte haben sie einen Augenblick geduld.
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="modal">
        <div className="box">
          <div className="text body-s">{error}</div>
        </div>
      </div>
    );
  }

  if (success) {
    // Redirect user to given search param. If not given, it defaults to dashboard-url
    const params = new URLSearchParams(location.search);
    const redirectUrl = params.get("redirect");

    console.log("redirectUrl", redirectUrl);
    console.log("query.destination", query.destination);
    console.log(
      "fin",
      redirectUrl ||
        (query.destination ? query.destination : redirectUrlFromSettings)
    );

    return (
      <Redirect
        to={
          redirectUrl ||
          (query.destination ? query.destination : redirectUrlFromSettings)
        }
      />
    );
  }

  return null;
};
