import React, {useState, useEffect} from "react";
import {graphql, withApollo} from "@apollo/client/react/hoc";
import artistsByTaxonomyQuery from "artists-by-taxonomy-query.graphql";
import {connect, useSelector} from "react-redux";
import TeaserArtist from "../../../teaser-base/artist/teaser-artist";
import groupBy from "lodash.groupby";
import {MultiTeaser} from "../teaser-overview-multi/multi-teaser";
import useOverviewContext from "./store/use-overview-context";
import OverviewProvider from "./store/overview-provider";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {useApolloClient} from "@apollo/client";
import {FormattedMessage} from "react-intl";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
  microSite: reduxStore.appStore.microSite,
});

const TeaserWrapper = ({content}) => {
  return (
    <div className="row artist-overview-teaser-wrapper">
      {content?.map((artist, artistIndex) => {

        const teaserContent = {
          image: artist.fieldBildWCaption?.entity.fieldMediaImage,
          title: artist.entityLabel,
          link: artist.path.alias,
          linkRouted: true,
          entityBundle: "artist"
        }

        return(
          <React.Fragment key={artistIndex}>
            <MultiTeaser
              content={teaserContent}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
}


const ArtistOverviewArtists = (props) => {
  const client = useApolloClient();
  const currentLanguage = useSelector(
    (reduxStore) => reduxStore.i18n.currentLanguage
  );

  const [artists, setArtists] = useState([]);

  /*
  *  "Komposition" and "Dirigieren" are special Cases in this logic.
  *  They have one level less, so this logic looks if the taxonomy has direct children.
  *  If not, it queries the artists directly -> "one layer to early".
  */
  useEffect(() => {
    if (typeof props.artists.nodeQuery?.entities !== "undefined" && !props.artists.nodeQuery?.entities?.length) {
      client.query({
        query: artistsByTaxonomyQuery,
        variables: {
          limit: 10000,
          taxonomyId: [props.filterByTaxonomy?.tid.toString()],
          language: currentLanguage.toUpperCase(),
          kvEnabled: true,
          ensembleEnabled: false
        }
      }).then(response => {
        setArtists(response.data?.nodeQuery?.entities);
      });
    } else {
      setArtists(props.artists.nodeQuery?.entities);
    }
  }, [props.artists, client, currentLanguage]);


  const [groupedArtists, setGroupedArtists] = useState([]);

  useEffect(() =>   {

    if (artists) {
      // All Artists grouped by their taxonomy @todo: can this force errors, because there could be spaces and special characters?
      let groupedArtists = false;

      if (props.type === "artist") {
        groupedArtists = groupBy(artists, artist => artist.fieldArtistCategory && artist.fieldArtistCategory[0] && artist.fieldArtistCategory[0].entity?.name);
      } else {
        groupedArtists = artists;
      }

      // Array to push objects consisting of Taxonomy info and Artists
      let groupedArtistsWithName = [];

      if (props.type === "artist") {
        Object.keys(groupedArtists).map((keyName) => {
          if (keyName !== "undefined") {
            groupedArtistsWithName.push({
              taxonomyName: keyName,
              artists: groupedArtists[keyName]
            });
          }
        });

        setGroupedArtists(groupedArtistsWithName.sort((a, b) => a.taxonomyName.localeCompare(b.taxonomyName)));
      } else {
        setGroupedArtists(groupedArtists);
      }
    }
  }, [artists, props.taxonomyId]);

  const goBackUrl = () => {
    const newSearchParams = location && new URLSearchParams(location.search);
    newSearchParams.delete("id");
    newSearchParams.delete("category");

    return `${location.pathname}?${newSearchParams.toString()}`;
  };

  return(
    <section className="artist-overview-artists">
      <div className="container">
        <div className="row">
          <div className="main-category-wrapper col-16">
            <h2>
              {/*Render Parent Name and Selected Category Name*/}
              {props.type === "artist" ? (
                <>
                  <Link to={goBackUrl}>{props?.filterByTaxonomy?.parent && props?.filterByTaxonomy?.parent[0]?.entity.name}</Link> /
                  {props.filterByTaxonomy?.name}
                </>
              ) : (
                <>Ensembles</>
              )}
            </h2>
          </div>
        </div>
      </div>


      {props.type === "artist" ? (
        <>
          {groupedArtists?.map((artistGroup, index) =>
            <div className="container" key={index}>
              <div className="row">
                <div className="col-16">
                  <h3 className="title-wrapper">
                    {artistGroup.taxonomyName}
                  </h3>
                </div>
              </div>

              <TeaserWrapper content={artistGroup.artists}/>
            </div>
          )}
        </>
      ) : (
        <div className="container">
          <TeaserWrapper content={groupedArtists}/>
        </div>
      )}

      {artists && groupedArtists.length === 0 &&
        <div className="container">
          <div className="row">
            <div className="col-16">
              <div className="text no-results">
                <FormattedMessage id="artists.no_result" />
              </div>
            </div>
          </div>
        </div>
      }
    </section>
  );
};

const ParagraphArtistOverviewArtists = ({ taxonomies, artists, filterByTaxonomy, groupedTaxonomies, type }) => {
  return (
    <OverviewProvider>
      <ArtistOverviewArtists
        groupedTaxonomies={groupedTaxonomies}
        artists={artists}
        filterByTaxonomy={filterByTaxonomy}
        type={type}
      />
    </OverviewProvider>
  );
};


export default connect(mapStateToProps)(
  graphql(artistsByTaxonomyQuery, {
    name: "artists",
    options: (props) => ({
      variables: {
        limit: 10000,
        // @todo: filter for parent Taxonomy?
        taxonomyId: props.filterByTaxonomy?.parent && props.groupedTaxonomies[props.filterByTaxonomy?.tid]?.map(tag => tag.tid.toString()),
        language: props.currentLanguage.toUpperCase(),
        kvEnabled: props?.type === "artist",
        ensembleEnabled: props?.type === "ensembles"
      },
    }),
  })(ParagraphArtistOverviewArtists)
);