import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import LoadingIndicator from "../general-components/loading-indicator";
import ContentBase from "../general-components/content-base/content-base";
import eventPageQuery from "./event.graphql";
import PagerFullPage from "../general-components/pager-full-page";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { i18nActionHelper } from "../i18n/i18n";
import Image from "../general-components/image/image";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { Participants } from "./participants";
import { Ticket } from "./ticket";
import { AddToCalendar } from "./add-to-calendar";
import { EventType } from "./event-type";
import EventSeries from "./event-series";
import { ClassCooperation } from "./class-cooperation";
import { Streamlink } from "./streamlink";
import { Location } from "./location";
import Error404 from "../error-404";
import EventDate from "./event-date";
import CheckForChildren from "../lib/check-for-children";
import ParagraphFormular from "../general-components/content-base/paragraphs/webform/paragraph-formular";
import CustomHyphenation from "../general-components/custom-hyphenation";
import NodeWrapper from "../node-wrapper";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  pagerConfig: reduxStore.appStore.pagerFullPages,
});

class Event extends Component {
  state = {
    queryStrings: queryString.parse(this.props.location.search),
    registrationOpened: false,
  };

  componentDidMount() {
    this.setState({
      queryStrings: queryString.parse(this.props.location.search),
    });

    i18nActionHelper(this.props, this.props.dispatch);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.location) !== JSON.stringify(this.props.location)
    ) {
      this.setState({
        queryStrings: queryString.parse(this.props.location.search),
      });
    }

    if (
      JSON.stringify(this.props.data.route) !==
      JSON.stringify(prevProps.data.route)
    ) {
      i18nActionHelper(this.props, this.props.dispatch);
    }

    if (
      this.props !== prevProps &&
      this.props.data?.route?.entity?.fieldRegistrationForm &&
      this.props.data?.route?.entity?.capacityLeft > 0
    ) {
      this.setState({
        registrationOpened: true,
      });
    }
  }

  render() {
    const entity = this.props.data.route?.entity;

    let eventPassed = false;
    if (this.props.data.route) {
      eventPassed = moment().diff(moment.unix(entity?.fieldDate.value)) > 0;
    }

    return (
      <NodeWrapper
        entity={entity}
        route={this.props.data.route}
        loading={this.props.data.loading}
      >
        <div className="container">
          <div className="row">
            {eventPassed && (
              <div className="col-16 col-lg-13 offset-lg-3">
                <div className={"archive-info"}>Archiv</div>
              </div>
            )}

            {entity?.fieldBild && (
              <div className="teaser-image col-16 offset-lg-4 col-lg-8">
                <Image
                  data={entity?.fieldBild.entity.fieldMediaImage}
                  nodeTitle={entity?.title}
                  credit={entity?.fieldBild.entity?.fieldCredit}
                  caption={entity?.fieldBild.entity?.customCaption}
                />
              </div>
            )}

            <div className={"col-16 col-lg-10 offset-lg-3"}>
              <div className="meta-wrapper">
                {(entity?.fieldPremiere || entity?.fieldDerniere) && (
                  <div className="premiere-derniere">
                    {entity?.fieldPremiere && (
                      <FormattedMessage id="event.premiere" />
                    )}
                    {entity?.fieldDerniere && (
                      <FormattedMessage id="event.derniere" />
                    )}
                  </div>
                )}

                <div className="date">
                  <EventDate content={entity} />
                </div>

                {entity?.fieldOrt && (
                  <div className="location">{entity?.fieldOrt}</div>
                )}
              </div>

              {entity?.title && (
                <h1>
                  {entity?.fieldOmitted && (
                    <FormattedMessage id={"event.omitted"} />
                  )}
                  <CustomHyphenation length={12}>
                    {entity?.title}
                  </CustomHyphenation>
                </h1>
              )}

              {entity?.fieldTitle2 && (
                <div
                  className="subline main body-m"
                  dangerouslySetInnerHTML={{
                    __html: entity?.fieldTitle2,
                  }}
                />
              )}

              {entity?.fieldSubtitle && (
                <div
                  className="subline"
                  dangerouslySetInnerHTML={{
                    __html: entity?.fieldSubtitle,
                  }}
                />
              )}

              {entity?.fieldTitleExtension && (
                <div
                  className="subline"
                  dangerouslySetInnerHTML={{
                    __html: entity?.fieldTitleExtension,
                  }}
                />
              )}

              <ClassCooperation content={entity} />

              {entity?.fieldEventCategory &&
                entity?.fieldEventCategory.length >= 1 && (
                  <div className="tag-wrapper">
                    {entity?.fieldEventCategory.map((item, index) => (
                      <React.Fragment key={index}>
                        {item.entity !== null && (
                          <span className="tag">{item.entity.name}</span>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                )}

              {entity?.body && (
                <div
                  className="text full"
                  dangerouslySetInnerHTML={{
                    __html: entity?.body.processed,
                  }}
                />
              )}

              <div className={"info-block"}>
                <div className="container">
                  <div className="row">
                    <div className="col-16 col-lg-8 main-wrapper">
                      <CheckForChildren classes={"cta-wrapper"}>
                        <EventType content={entity} />

                        <Streamlink content={entity} />

                        {!eventPassed && <AddToCalendar content={entity} />}
                      </CheckForChildren>

                      {(entity?.fieldLocation || entity?.fieldBuilding) && (
                        <div className="address">
                          {entity?.fieldBuilding && (
                            <div className="building">
                              {entity?.fieldBuilding}
                            </div>
                          )}
                          <Location content={entity} />
                        </div>
                      )}
                      {!eventPassed && <Ticket content={entity} />}
                    </div>
                    {entity?.fieldParticipating && (
                      <div className="col-16 col-lg-8 participating-wrapper">
                        <Participants content={entity} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {entity?.fieldModules && (
          <ContentBase content={entity?.fieldModules} nodeContent={entity} />
        )}

        {this.state.registrationOpened ? (
          <div className={"registration-block"}>
            <div className="container">
              <div className="row">
                <div className="col-16 col-lg-10 offset-lg-3">
                  <h3 className="title">
                    <FormattedMessage id={"event.registration"} />
                  </h3>
                </div>
              </div>
            </div>
            {entity?.fieldSpecialization?.entity?.name === "Career Service" ? (
              <ParagraphFormular
                content={{
                  fieldFormularAuswahl: {
                    targetId: "system_event_registration_caree",
                  },
                }}
                eventId={entity?.entityId}
                capacityLeft={entity?.capacityLeft}
              />
            ) : (
              <ParagraphFormular
                content={{
                  fieldFormularAuswahl: {
                    targetId: "system_event_registration",
                  },
                }}
                eventId={entity?.entityId}
                customFieldEnabled={entity?.fieldRegistrationFreeField}
                customFieldLabel={
                  entity?.fieldFreeFieldLabel
                    ? entity?.fieldFreeFieldLabel
                    : false
                }
                capacityLeft={entity?.capacityLeft}
              />
            )}
          </div>
        ) : null}

        {entity?.fieldEventSeries && !eventPassed && (
          <EventSeries content={entity} />
        )}

        {this.state.queryStrings.p &&
          this.props.pagerConfig.filter(
            (config) => config.id === this.state.queryStrings.p
          ).length > 0 && (
            <PagerFullPage
              config={
                this.props.pagerConfig.filter(
                  (config) => config.id === this.state.queryStrings.p
                )[0]
              }
              currentId={entity?.entityId}
            />
          )}
      </NodeWrapper>
    );
  }
}

Event.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.object,
};

export default graphql(eventPageQuery, {
  options: (props) => ({
    variables: { path: props.location.pathname },
  }),
})(connect(mapStateToProps)(withRouter(Event)));
