import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import {encodeUrl} from "../lib/encode-url";
import {useSelector} from "react-redux";

const Menu = ({
    items,
    depth = 1,
    level = 1,
    ...props
  }) => {

  const currentLanguage = useSelector((reduxStore) => reduxStore.i18n.currentLanguage);

  return (
    <ul data-level-active={props.level === props.activeLevel} data-level={props.level}>
      {items && items.map((item, i) => (
        <React.Fragment key={i}>
          {/* o render necessary */}
          {depth === level &&
            <>
              {item.url?.path === "" ? (
                <li
                  data-menu-active={props.activeLink && props.activeLink.includes(encodeUrl(item.label))}
                >
                  <button
                    className="no-link"
                    tabIndex={0}
                    onClick={() => props.clickLevelLink(level + 1, encodeUrl(item.label))}
                  >
                    {item.label}
                  </button>
                </li>
              ) : (
                <li
                  className="link"
                  onClick={() => props.toggleFullScreenMenu()}
                  data-is-application-link={item.url?.path === "/rund-um-die-bewerbung" || item.url?.path === "/application"}
                >
                  {item.url.routed ? (
                    <Link to={currentLanguage === "en" && item.url?.path.includes("node") ? item.url?.de?.path : item.url?.path}>{item.label}</Link>
                  ) : (
                    <a href={currentLanguage === "en" && item.url?.path.includes("node") ? item.url?.de?.path : item.url?.path} target={"_blank"}>{item.label}</a>
                  )}
                </li>
              )}
            </>
          }
          {!!item.links?.length && (
            <div
              className="nested-menu-wrapper"
              data-menu-active={props.activeLink && props.activeLink.includes(encodeUrl(item.label))}
            >
              {/* First Level Label Mobile */}
              {level !== 1 && ((props.activeLink.length + 1 === level) || props.activeLink[level - 2] === item.label.toLowerCase()) &&
                <div
                  className={`mobile-label d-lg-none ${level}`}
                >
                  <span className={"no-link"}>
                    {item.label}
                  </span>
                </div>
              }
              <Menu
                depth={depth + 1}
                items={item.links}
                level={level}
                clickLevelLink={props.clickLevelLink}
                activeLink={props.activeLink}
                toggleFullScreenMenu={props.toggleFullScreenMenu}
              />
            </div>
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};

Menu.propTypes = {
  items: PropTypes.array.isRequired,
  depth: PropTypes.number,
  megaMenu: PropTypes.object,
  effects: PropTypes.bool,
  className: PropTypes.string,
};

export default Menu;