import React, { useRef, useState, useEffect, forwardRef } from "react";
import Image from "../../../image/image";
import {Slide, Slider, SliderControls} from "../../../slider/slider";
import GalleryArrow from "../../../icons/gallery-arrow";
import ErrorBoundary from "../../../../error-boundary";
import EmbeddedVideo from "../media-video-audio/embedded-video";
import LocalVideo from "../media-video-audio/local-video";
import classNames from "classnames";
import GalleryIndexInfo from "../../../gallery-index-info";
import CustomHyphenation from "../../../custom-hyphenation";
import SliderProvider from "../../../slider/context";
import {useSelector} from "react-redux";

const SliderComponent = forwardRef((props, sliderRef) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderComponentRef = useRef(null);
  const updateSlideIndex = (next) => {
    setSlideIndex(next);
  }
  const isMobile = useSelector((state) => state.appStore.isMobile);

  const sliderSettings = {
    "full-bleed": {
      loop: true,
      align: isMobile ? "start" : "center",
    },
    standard: {
      loop: true,
      align: "start",
    },
  };

  useEffect(() => {
    // @todo: I know, this is an ugly solution. But after 1.5h of thinking and testing there was no better way with this structure.
    if (!props.globalCaption) {
      setTimeout(() => {
        let highestCaptionHeight = Math.max(
          ...[...sliderComponentRef?.current.querySelectorAll(".info-wrap")].map(item => item.getBoundingClientRect().height)
        );

        if (!Number.isFinite(highestCaptionHeight)) {
          highestCaptionHeight = 0;
        }

        sliderComponentRef?.current?.style.setProperty('--highest-caption-height', `${highestCaptionHeight}px`);
      }, 750);
    }

  }, [sliderComponentRef]);

  const sliderContent = props.formatSmall ? props.content : [...props.content, ...props.content];

  return (
    <SliderProvider options={props.formatSmall ? sliderSettings["standard"] : sliderSettings["full-bleed"]}>
      <div
        className={classNames({
          "custom-slider-component": true,
          "small": props.formatSmall,
          "standard": !props.formatSmall,
          "has-global-caption": props.globalCaption,
        })}
        ref={sliderComponentRef}
      >
        <div className={"slider-wrapper"}>

          {!props.formatSmall &&
            <SliderControls />
          }

          {props.content &&
            <Slider>
              {sliderContent.map((item, index) =>
                <Slide key={`${item?.entity?.entityId}-${index}`}>
                  <div className="media-wrap">
                    {item.entity?.__typename === "MediaBild" &&
                      <ErrorBoundary>
                        <article
                          className={`media-image ${item.entity.fieldMediaImage.style.height / item.entity.fieldMediaImage.style.width > 1.2 ? 'portrait' : 'landscape'}`}
                          key={`${item?.entity?.entityId}-${index}`}
                        >
                          <Image
                            data={item.entity.fieldMediaImage}
                            format4To3={props.formatSmall && item.entity.fieldMediaImage.style.height / item.entity.fieldMediaImage.style.width < 1.2}
                            caption={props.globalCaption || item.entity.fieldBildunterschrift}
                            inSlider={true}
                            credit={item.entity.fieldCredit}
                          />
                        </article>

                        {!props.globalCaption && (item.customCaption || item.entity.fieldBildunterschrift || item.entity.fieldCredit) &&
                          <div className="info-wrap">
                            {(item.customCaption || item.entity.fieldBildunterschrift) &&
                              <span className="caption">
                            {!item.defaultCaption && item.customCaption ? (
                              <>
                                {item.customCaption}
                              </>
                            ) : (
                              <>
                                {item.entity.fieldBildunterschrift}
                              </>
                            )}
                          </span>
                            }
                            {item.entity.fieldCredit &&
                              <span className="credit">Foto: {item.entity.fieldCredit}</span>
                            }
                          </div>
                        }
                      </ErrorBoundary>
                    }

                    {item.entity?.__typename === "MediaVideoEmbed" &&
                      <ErrorBoundary>
                        <React.Fragment key={`${item?.entity?.entityId}-${index}`}>
                          <EmbeddedVideo content={item.entity}/>
                          {item.customCaption &&
                            <div
                              className={classNames({
                                "caption-wrap info-wrap": true
                              })}
                            >
                              {item.customCaption && (
                                <span className="caption">
                              <CustomHyphenation length={6}>
                                {item.customCaption}
                              </CustomHyphenation>
                              </span>
                              )}
                            </div>
                          }
                        </React.Fragment>
                      </ErrorBoundary>
                    }

                    {item.entity?.__typename === "MediaVideo" &&
                      <ErrorBoundary>
                        <React.Fragment key={`${item?.entity?.entityId}-${index}`}>
                          <LocalVideo
                            content={item.entity}
                            thumbnail={item.entity.fieldImage}
                          />
                          {item.customCaption &&
                            <div
                              className={classNames({
                                "caption-wrap info-wrap": true
                              })}
                            >
                              {item.customCaption && (
                                <span className="caption">
                                <CustomHyphenation length={6}>
                                  {item.customCaption}
                                </CustomHyphenation>
                            </span>
                              )}
                            </div>
                          }
                        </React.Fragment>
                      </ErrorBoundary>
                    }
                  </div>
                </Slide>
              )}
            </Slider>
          }
        </div>

        {props.formatSmall &&
          <GalleryIndexInfo
            slideIndex={slideIndex}
            content={props.content}
            type={"embla"}
          />
        }

        {props.globalCaption &&
          <div className="global-caption">
            <div className="container">
              <div className="row">
                <div className="col-16">
                  <span className="caption info-wrap">
                    <CustomHyphenation length={6}>
                      {props.globalCaption}
                    </CustomHyphenation>
                  </span>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </SliderProvider>
  );
});
{/*
SliderComponent.propTypes = {
  content: PropTypes.shape({
    __typename: PropTypes.string,
    entityBundle: PropTypes.string,
    entityId: PropTypes.string,
    fieldBildunterschrift: PropTypes.string,
    fieldBilder: PropTypes.array,
    fieldSmall: PropTypes.bool,
    fieldColorscheme: PropTypes.shape({
      __typename: PropTypes.string,
      entity: PropTypes.shape({
        __typename: PropTypes.string,
        filedColorVariable: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
    fieldMultimedia: PropTypes.arrayOf(
      PropTypes.shape({
        __typename: PropTypes.string,
        customCaption: PropTypes.string,
        defaultCaption: PropTypes.bool,
        entity: PropTypes.shape({
          __typename: PropTypes.string,
          fieldBildunterschrift: PropTypes.string,
          fieldCredit: PropTypes.string,
          fieldMediaImage: PropTypes.shape({
            __typename: PropTypes.string,
            alt: PropTypes.string,
            title: PropTypes.string,
            style: PropTypes.shape({
              height: PropTypes.number,
              weight: PropTypes.number,
              url: PropTypes.string,
              __typename: PropTypes.string,
            }),
            format4To3: PropTypes.shape({
              url: PropTypes.string,
              __typename: PropTypes.string,
            }),
          }),
        }),
      }),
    )
  }),
};
*/}

export default SliderComponent;