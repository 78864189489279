import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

const NewsletterConfirm = () => {
  const { action, status } = useParams();

  const messages = {
    subscribe: {
      success: {
        headline: "newsletter.confirm.subscribe.success.headline",
        body: "newsletter.confirm.subscribe.success.body",
      },
      error: {
        headline: "newsletter.confirm.subscribe.error.headline",
        body: "newsletter.confirm.subscribe.error.body",
      },
    },
    unsubscribe: {
      success: {
        headline: "newsletter.confirm.unsubscribe.success.headline",
        body: "newsletter.confirm.unsubscribe.success.body",
      },
      error: {
        headline: "newsletter.confirm.unsubscribe.error.headline",
        body: "newsletter.confirm.unsubscribe.error.body",
      },
    },
  };

  return (
    <article className="newsletter-confirm">
      <section className="content-wrap">
        <section className="paragraph">
          <div className="container">
            <div className="row">
              <div className="col-16">
                <h1><FormattedMessage id={messages[action][status]?.headline} /></h1>
                <div className="text">
                  <p><FormattedMessage id={messages[action][status]?.body} /></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </article>
  );
};

export default NewsletterConfirm;
