import React, {useEffect, useState} from 'react';
import { useSliderContext } from "./slider/context";

const GalleryIndexInfo = ({ content, slideIndex, sliderRef, type }) => {
  if (type === "embla") {
    const { emblaApi } = useSliderContext();
    const [currentSlide, setCurrentSlide] = useState(slideIndex);

    useEffect(() => {
      if (type === "embla") {
        setCurrentSlide(emblaApi?.selectedScrollSnap() + 1);
      }
    }, [emblaApi]);

    const nextSlide = () => {
      if (type === "embla") {
        emblaApi?.scrollNext();
        setCurrentSlide(emblaApi?.selectedScrollSnap() + 1);
      } else {
        sliderRef.current?.slickNext();
      }
    };

    return (
      <div className={"index-info"} onClick={nextSlide}>
        {currentSlide}/{content.length}
      </div>
    );
  } else {
    const [currentSlide, setCurrentSlide] = useState(slideIndex);

    const nextSlide = () => {
      sliderRef.current?.slickNext();
    };

    return (
      <div className={"index-info"} onClick={nextSlide}>
        {slideIndex + 1}/{content.length}
      </div>
    );
  }
};

export default GalleryIndexInfo;
