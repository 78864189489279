import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import ErrorBoundary from "../../../../error-boundary";
import Image from "../../../image/image";
import VisibilitySensor from "react-visibility-sensor";
import { encodeUrl } from "../../../../lib/encode-url";
import HeadlineSelectableSize from "../../../headline-selectable-size";

class ParagraphClickSuggestions extends Component {
  suggestions = React.createRef();

  state = {
    linkHeight: 50,
    visibleIndex: null, // Store the currently visible element index
    lastScrollTop: 0, // Track the last scroll position
    scrollDirection: "down", // Track the current scroll direction
  };

  handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    this.setState({
      scrollDirection: scrollTop > this.state.lastScrollTop ? "down" : "up",
      lastScrollTop: scrollTop,
    });
  };

  componentDidMount() {
    // Get link height
    const linkHeight =
      this.suggestions.current.querySelector("li")?.clientHeight + 4;
    this.setState({ linkHeight });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  onMouseEnter = (e) => {
    let offset = 0,
      element = e;

    if (e.target) {
      element = e.target;
    }
    if (window.innerWidth > 768) {
      offset = window.innerWidth * 0.25;
    }

    if (element.offsetWidth > window.innerWidth - 100) {
      element.style.marginLeft = `-${
        element.offsetWidth - (window.innerWidth - 175 - offset)
      }px`;
    }
  };

  onMouseLeave = (e) => {
    let element = e;

    if (e.target) {
      element = e.target;
    }
    if (element.offsetWidth > window.innerWidth - 100) {
      element.style.marginLeft = 0;
    }
  };

  onChange = (isVisible, index) => {
    // Ensure only one element remains active at a time
    if (isVisible) {
      this.setState({ visibleIndex: index });
    } else if (this.state.visibleIndex === index) {
      this.setState({ visibleIndex: null });
    }
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-click-suggestions": true,
    });

    return (
      <section className={sectionClassNames} ref={this.suggestions}>
        <div className="container">
          <div className="row">
            <div className="col-16">
              <HeadlineSelectableSize
                content={this.props.content.fieldHeading}
                size={"h3"}
                customClassNames={"section-heading"}
              />
            </div>
          </div>
        </div>
        <ul className="container fluid">
          {this.props.content.fieldLinkInternalExternal.map((item, index) => {
            let internalLink =
              item?.entity?.fieldInternalContent?.entity?.entityUrl?.path;
            if (internalLink && item?.entity.fieldAnchorLink) {
              internalLink = `${internalLink}#${item.entity.fieldAnchorLink}`;
            }

            if (item && item?.entity) {
              return (
                <React.Fragment key={index}>
                  <VisibilitySensor
                    partialVisibility={false}
                    onChange={(isVisible) => this.onChange(isVisible, index)}
                    active={
                      typeof window !== "undefined" && window.innerWidth < 768
                    }
                    offset={{
                      top:
                        typeof screen !== "undefined" &&
                        this.state.scrollDirection === "up"
                          ? screen.height / 2 - this.state.linkHeight / 2
                          : 0,
                      bottom:
                        typeof screen !== "undefined" &&
                        this.state.scrollDirection === "down"
                          ? screen.height / 2 - this.state.linkHeight / 2
                          : 0,
                    }}
                  >
                    {({ isVisible }) => (
                      <>
                        {item?.entity.entityBundle === "external_link" && (
                          <li
                            className={`row ${
                              this.state.visibleIndex === index
                                ? "active"
                                : "inactive"
                            }`}
                          >
                            <div className="col-wrapper col-16">
                              <a
                                href={item.entity.fieldExternalLink.url.path}
                                target={"_blank"}
                                onMouseEnter={(e) =>
                                  this.onMouseEnter(e.target)
                                }
                                onMouseLeave={(e) =>
                                  this.onMouseLeave(e.target)
                                }
                                className="external-link"
                              >
                                {item.entity.fieldExternalLink.title}
                              </a>
                            </div>
                          </li>
                        )}
                        {item?.entity.entityBundle === "internal_link" &&
                          item.entity.fieldInternalContent?.entity && (
                            <li
                              className={`row ${
                                this.state.visibleIndex === index
                                  ? "active"
                                  : "inactive"
                              }`}
                            >
                              <div className="col-wrapper col-16">
                                <ErrorBoundary>
                                  <Link
                                    to={internalLink}
                                    onMouseEnter={(e) => this.onMouseEnter(e)}
                                    onMouseLeave={(e) => this.onMouseLeave(e)}
                                    className="internal-link"
                                  >
                                    {item.entity.fieldAlternativeLabel ? (
                                      <>{item.entity.fieldAlternativeLabel}</>
                                    ) : (
                                      <>
                                        {
                                          item.entity.fieldInternalContent
                                            .entity.entityLabel
                                        }
                                      </>
                                    )}
                                  </Link>
                                </ErrorBoundary>
                              </div>
                            </li>
                          )}
                      </>
                    )}
                  </VisibilitySensor>
                </React.Fragment>
              );
            }
          })}
        </ul>
      </section>
    );
  }
}

ParagraphClickSuggestions.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldLinkInternalExternal: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default ParagraphClickSuggestions;
