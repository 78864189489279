import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import LoadingIndicator from "../general-components/loading-indicator";
import ContentBase from "../general-components/content-base/content-base";
import NewsQuery from "./news.graphql";
import PagerFullPage from "../general-components/pager-full-page";
import { i18nActionHelper } from "./../i18n/i18n";
import FieldTime from "../general-components/date-time/field-time";
import Image from "../general-components/image/image";
import CustomHyphenation from "../general-components/custom-hyphenation";
import NodeWrapper from "../node-wrapper";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  pagerFullPageContextId: reduxStore.appStore.pagerFullPageContextId,
  pagerConfig: reduxStore.appStore.pagerFullPages,
  microSite: reduxStore.appStore.microSite,
  microSiteFrontPage: reduxStore.appStore.microSiteFrontPage,
});

class News extends Component {
  componentDidMount() {
    i18nActionHelper(this.props, this.props.dispatch);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.data.route) !==
      JSON.stringify(prevProps.data.route)
    ) {
      i18nActionHelper(this.props, this.props.dispatch);
    }
  }

  render() {
    const entity = this.props.data.route?.entity;

      return (
        <NodeWrapper entity={entity} route={this.props.data?.route} loading={this.props.data?.loading}>
          <div className="news-head">
            <div className="container">
              <div className="row">
                {entity?.fieldTeaserbild && (
                  <div className="teaser-image col-16 offset-lg-4 col-lg-8 col-xl-8 offset-lg-4">
                    <Image
                      data={entity?.fieldTeaserbild.entity?.fieldMediaImage}
                      nodeTitle={entity?.title}
                      />
                  </div>
                )}
                {entity?.entityLabel &&
                  <div className="col-16 col-lg-12 offset-lg-2">
                    <h1>
                      <CustomHyphenation length={8}>
                        {entity?.entityLabel}
                      </CustomHyphenation>
                    </h1>
                  </div>
                }
                {entity?.fieldSchlagwort &&
                  <div className="col-16 col-lg-12 offset-lg-2">
                    <div className="tag-wrapper">
                      {entity?.fieldSchlagwort.map((item, index) => (
                        <React.Fragment key={index}>
                          {item.entity !== null && (
                            <span className="tag s">{item.entity?.name}</span>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                }
                {entity?.publishedAt &&
                  <div className="col-16 col-lg-12 offset-lg-3 date-info">
                    <FieldTime
                      timestamp={true}
                      date={
                        entity?.publishedAt.value
                          ? entity?.publishedAt.value
                          : entity?.publishedAt.publishedAtOrNow
                      }
                      format={"D. MMMM YYYY"}
                    />
                  </div>
                }
                {entity?.fieldText &&
                  <div className="col-16 col-lg-12 offset-lg-2">
                    <div
                      className="text teaser-text"
                      dangerouslySetInnerHTML={{
                        __html: `${entity?.fieldText.processed}`,
                      }}
                      />
                  </div>
                }
              </div>
            </div>

          </div>

          {entity?.fieldModules && (
            <ContentBase content={entity?.fieldModules} />
          )}

          {this.props.pagerFullPageContextId &&
            this.props.pagerConfig.filter(
              (config) => config.id === this.props.pagerFullPageContextId
            ).length > 0 && (
              <PagerFullPage
              config={
                  this.props.pagerConfig.filter(
                    (config) => config.id === this.props.pagerFullPageContextId
                  )[0]
                }
                currentId={entity?.entityId}
              />
            )}
        </NodeWrapper>
      );
  }
}

News.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.array,
  pagerFullPageContextId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
};

export default connect(mapStateToProps)(
  graphql(NewsQuery, {
    options: (props) => ({
      variables: {
        path:
          props.location.pathname === "/" &&
          props.microSite &&
          props.microSiteFrontPage !== ""
            ? props.microSiteFrontPage
            : props.location.pathname,
      },
    }),
  })(withRouter(News))
);